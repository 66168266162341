/* Popup style */
.popup-box {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999999;
}

.popup-box p {
  max-width: inherit !important;
  color: black;
  text-shadow: none !important;
}

.popup-box li {
  color: black;
}

.popup-box h4 {
  color: orange;
  font-size: 1.25em;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 1em);
  background: #fff;
  border-radius: 4px;
  padding: 1.25em;
  border: 1px solid rgb(102, 102, 102);
  overflow: auto;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: calc(15% - 1.5em);
  top: calc(100vh - 85vh - 2em);
  background: #999999;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  line-height: 1.25em;
  font-size: 1.25em;
  text-align: center;
}
