@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.news-page ul {
  list-style: none;
}

.news-page li::before {
  content: '\2022';
  color: #dc8a00;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.news-page table {
  margin-bottom: 1em;
}

@include media-breakpoint-down(md) {
  #blog-list .content-section {
    padding: 30px 0;
  }
}

#blog-list h5 {
  color: white;
}
