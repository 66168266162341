@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#about .info {
  width: calc(100% - 500px);
  min-height: 200px;
  float: right;
}

#about .info p {
  margin-top: 20px;
  font-size: 16px;
  color: #cccccc;
  text-align: justify;
  text-justify: inter-word;
}

#about .info i {
  margin-top: 10px;
  margin-right: 30px;
}

#about .info a {
  text-decoration: none;
  color: inherit;
}

#about .info a:hover {
  opacity: 0.3;
}

.content-section {
  background: none;
}

#features-home {
  background: #141414;
}

#about,
#news,
#footer {
  background: #232323;
}

@include media-breakpoint-down(md) {
  #news .content-section.homepage,
  #features-home .content-section.homepage {
    padding: 40px 0;
  }
}

@media (max-width: 768px) {
  #about .content-section {
    padding: 50px 0;
  }

  #about .info {
    width: 90%;
    margin: 50px auto auto;
    display: block;
    float: none;
  }
}

.right-header {
  border-bottom: 1px solid #dc8a00;
  padding-bottom: 10px;
  width: auto;
  font-size: 12px;
  float: right;
  vertical-align: bottom;
  margin-top: 8px;
}

@media (max-width: 488px) {
  .content-section h1 {
    font-size: 18px;
  }
  .right-header {
    font-size: 10px;
    border-bottom: none;
    padding: 0;
    margin-top: 10px;
  }
}
