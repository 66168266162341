@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.card {
  border: none;
  background: none;
  margin-bottom: 50px;
}

.card-header,
.card-body {
  padding: 0;
  margin-top: 20px;
  font-size: 15px !important;
  color: #a8a8a8;
}

.card-title {
  color: white;
  font-size: 18px !important;
}

.card-title a {
  color: inherit;
}
